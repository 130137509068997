import { Component, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { PolicyService } from '../../_policyData/policy.service';
import { IPolicy } from '../../_policyData/Policy';
import { LoginService } from '../login/login.service';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BaseComponent } from '../base/base';
import { NgxSpinnerService } from "ngx-spinner";
import { environment } from '../../../environments/environment';
declare var $: any;

@Component({
  selector: 'prov-home',
  templateUrl: './home.html',
  styleUrls: ['./home.css']
})
export class HomeComponent extends BaseComponent implements OnInit {
  @ViewChild(NgForm) policySearch: NgForm;
  public mask = [/\d/, /\d/, '/', /\d/, /\d/, '/', /\d/, /\d/, /\d/, /\d/];
  pageTitle = 'Policy Search';
  showDialog = false;
  errorMessage: string;
  policyNum: string;
  dateOfBirth: string;
  currentPolicy: IPolicy;
  loading = false;
  noMatchMessage: string = "Sorry, no match found for policy number and policy holder date of birth."

  constructor(private policyService: PolicyService, private loginService: LoginService, private router: Router, private spinner: NgxSpinnerService) {
    super();
  }


  get isValid(): boolean {
    return this.policyNum && this.dateOfBirth && this.policyNum.length > 9 && this.policyNum.length < 11;
  }

  SearchPolicy() {
    this.loading = true;
    this.spinner.show();
    this.getPolicy();
  }

  getPolicy() {
    if (this.policyNum === "0000000000")
      this.policyNum = environment.settings.demoPolicyNumber;
    this.policyService.getPolicy(this.policyNum).subscribe(p =>
      this.validatePolicy(p),
      () => {
        this.spinner.hide();
        this.loading = false;
        this.errorMessage = this.noMatchMessage;
      }
    );
  }

  validatePolicy(policy: IPolicy) {
    if (policy == null) {
      this.errorMessage = this.noMatchMessage;
      this.loading = false;
      this.spinner.hide();
    } else {
      if ((policy && moment(policy.DateOfBirth).isSame(this.dateOfBirth, 'day')) || (this.policyNum === environment.settings.demoPolicyNumber && this.dateOfBirth === "00/00/0000")) {
        if (this.policyNum === environment.settings.demoPolicyNumber && this.dateOfBirth === "00/00/0000")
          policy = this.setDemoPolicy(policy);
        this.policyService.getClaimsByProvider(policy, this.loginService.currentProvider.ProviderID).subscribe(p => {
          this.currentPolicy = p;
          this.policyService.changePolicy(p);
          this.loading = false;
          this.router.navigate(['home/policy']);
          this.errorMessage = '';
          this.spinner.hide();
        },
          () => {
            this.spinner.hide();
            this.loading = false;
            this.errorMessage = this.noMatchMessage;
          });
      } else {
        this.errorMessage = this.noMatchMessage;
        this.loading = false;
        this.spinner.hide();
      }
    }
  }

  ngOnInit() {
    this.currentPolicy = this.policyService.getCurrentPolicy();
    if (this.currentPolicy) {
      this.policyNum = this.currentPolicy.PolicyNumber;
      const theDateIamMessingWith = new Date(this.currentPolicy.DateOfBirth);
      this.dateOfBirth = moment(theDateIamMessingWith).format('MM/DD/YYYY');
    }
  }
  setDemoPolicy(policy: IPolicy): IPolicy {
    policy.FirstName = "Provider";
    policy.LastName = "User";
    policy.DateOfBirth = new Date("July 21, 1930");
    return policy;
  }
}
